import React from 'react';
import tw from 'twin.macro';

import type { TwStyle } from 'twin.macro';

import { VARIANT } from '../../enums';

interface CalloutProps {
  variant: VARIANT;
}

const Callout: React.FC<CalloutProps> = ({ variant, children }) => {
  const baseStyles = tw`p-6  rounded-tr-4xl w-full max-w-5xl`;
  let colourStyles: TwStyle | null = null;
  switch (variant) {
    case 'green':
      colourStyles = tw`bg-green text-gray-800`;
      break;
    case 'blue':
      colourStyles = tw`bg-blue-700 text-gray-50`;
      break;
    case 'rose':
      colourStyles = tw`bg-rose text-gray-50`;
      break;
    case 'red':
      colourStyles = tw`bg-red text-gray-50`;
      break;
    case 'orange':
      colourStyles = tw`bg-orange text-gray-800`;
      break;
    case 'yellow':
      colourStyles = tw`bg-yellow-200 text-gray-800`;
      break;
  }

  return <div css={[baseStyles, colourStyles]}>{children}</div>;
};

export default Callout;
