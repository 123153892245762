import React from 'react';
import 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '~components/Layout';
import SEO from '~components/SEO';
import Hero from '~components/Hero';
import Heading from '~components/Heading';
import Callout from '~components/Callout';
import CaptionImage from '~components/CaptionImage';
import Text from '~components/Text';

import { roundedImg } from '~theme';

import { VARIANT } from '../enums';

const IntroPage: React.FC = () => (
  <Layout>
    <SEO
      title="Introduction to the Plan"
      desc="I think of our new plan as a compass, pointing the direction to our destination."
      pathname="/intro"
    />
    <Hero title="Introduction to the Plan">
      <StaticImage layout="fullWidth" src="../images/student-looking-at-monitors.jpg" alt="" />
    </Hero>
    <div tw="container">
      <div tw="grid gap-12 md:(grid-cols-3)">
        <div tw="md:(col-span-2)">
          <Heading level="h2" tw="mt-0">
            Letter from the President
          </Heading>
          <Callout variant={VARIANT.GREEN}>
            <Text tw="my-0 text-2xl font-bold">
              Our new strategic plan is attuned to these unusual and demanding times. It meets our
              short-term needs for recovery as a college and a community, and sets an innovative
              course for our future.
            </Text>
          </Callout>
          <Text>
            As we developed this plan, we were navigating the huge impact of the pandemic, with much
            upheaval and uncertainty.
          </Text>
          <Text>
            We worked hard to balance managing the crisis, with the need to continue important
            planning to support a healthy future for our college. Some people may think that
            planning during a time of great change is difficult or perhaps even unwise.
          </Text>
          <Callout variant={VARIANT.ORANGE}>
            <Text tw="my-0 text-2xl font-bold">
              At Mohawk, we recognized that the pandemic provided an opportunity to look at how we
              can do things differently.
            </Text>
          </Callout>
          <Text>
            Our three-year plan allows us to continue to move forward with ambitious goals, along
            with emphasizing the need to be flexible to support recovery through innovation.
          </Text>
          <Callout variant={VARIANT.ROSE}>
            <Text tw="my-0 text-2xl font-bold">
              If we’ve learned anything from our pandemic experience, it’s that we need to be able
              to adapt to change.
            </Text>
          </Callout>
        </div>
        <div tw="md:(col-span-1)">
          <CaptionImage
            variant={VARIANT.GREEN}
            caption="Ron McKerlie, President of Mohawk College. "
          >
            <StaticImage
              style={roundedImg}
              src="../images/president.jpg"
              alt=""
              layout="fullWidth"
            />
          </CaptionImage>
        </div>
      </div>
    </div>
    <div tw="bg-green text-gray-800 py-12 my-12">
      <div tw="container">
        <div tw="grid gap-12 md:(grid-cols-3)">
          <div tw="md:(col-span-2)">
            <Heading level="h2" tw="mt-0">
              A Compass, Not a Roadmap
            </Heading>
            <Callout variant={VARIANT.RED}>
              <Text tw="my-0 text-2xl font-bold">
                Our new plan is a compass, pointing us in the right direction.
              </Text>
            </Callout>
            <Text>
              Unlike a roadmap which, is detailed and specific, a compass provides direction but
              allows us to adjust our route along the way.
            </Text>
            <Text>
              Our new plan gives us room to respond to current realities and emerging opportunities.
              Through our annual business planning process, we will develop timely actions and
              targets to achieve each year, while continually moving towards our ambitious
              leadership outcomes.
            </Text>
          </div>
          <div tw="md:(col-span-1)">
            <CaptionImage variant={VARIANT.ROSE} caption="Compass.">
              <StaticImage
                style={roundedImg}
                src="../images/compass.jpg"
                alt=""
                layout="fullWidth"
              />
            </CaptionImage>
          </div>
        </div>
      </div>
    </div>
    <div tw="container my-24">
      <div tw="grid gap-12 md:(grid-cols-3)">
        <div tw="md:(col-span-2)">
          <Heading level="h2" tw="my-0">
            Building On Our Strengths
          </Heading>
          <Text tw="text-2xl font-bold">Our new strategic plan has four components:</Text>
          <div tw="flex flex-col space-y-6">
            <Callout variant={VARIANT.ROSE}>
              <Heading level="h3" tw="mt-0">
                Aspirations
              </Heading>
              <Text tw="mb-0">Where we want the college to be.</Text>
            </Callout>
            <Callout variant={VARIANT.RED}>
              <Heading level="h3" tw="mt-0">
                Strategic Directions
              </Heading>
              <Text tw="mb-0">How we are going to get there.</Text>
            </Callout>
            <Callout variant={VARIANT.ORANGE}>
              <Heading level="h3" tw="mt-0">
                Leadership Outcomes
              </Heading>
              <Text tw="mb-0">What it looks like when we arrive.</Text>
            </Callout>
            <Callout variant={VARIANT.GREEN}>
              <Heading level="h3" tw="mt-0">
                Strong Foundations
              </Heading>
              <Text tw="mb-0">
                What support is required to fuel our directions and get us to our outcomes.
              </Text>
            </Callout>
          </div>
          <Text>
            It builds on the plan we’ve just completed, and on what we do well. It is guided by our
            reconfirmed vision, mission and values.
          </Text>
          <Text>
            It is firmly positioned on a strong foundation &mdash; People, Planning, Reputation,
            Technology and Financial Health. These essential underpinnings ensure that we can
            progress toward our aspirations and, ultimately, achieve our leadership outcomes.
          </Text>
        </div>
        <div tw="md:(col-span-1)">
          <CaptionImage variant={VARIANT.GREEN} caption="Students at Mohawk College">
            <StaticImage
              style={roundedImg}
              src="../images/students-in-fanuc-lab.jpg"
              alt=""
              layout="fullWidth"
            />
          </CaptionImage>
        </div>
      </div>
    </div>
    <div tw="container my-24">
      <div tw="grid gap-12 md:(grid-cols-3)">
        <div tw="md:(col-span-2)">
          <Heading level="h2" tw="mt-0">
            This Plan Belongs To Us All
          </Heading>
          <Text>
            Over the span of 10 months, we consulted with over 1,000 stakeholders across the
            college, and with our partners and the community. We held open forums, one-on-one
            interviews, sent out surveys and attended departmental sessions. Commitment to the
            process from both our internal and external stakeholders was exceptional. We received
            thought-provoking and innovative input from our students, our board, employees, alumni
            and community members that was especially critical to shaping the plan.
          </Text>
          <Text>
            As we worked through the process, we often began our sessions by asking participants to
            describe Mohawk using one word. Throughout those conversations, six words were mentioned
            repeatedly: collaborative, community, innovative, opportunity, resilient, welcoming.
            Each word resonates on its own. Together, they vibrantly reflect our college and animate
            our new strategic plan.
          </Text>
          <Text>This plan belongs to us all.</Text>
          <Callout variant={VARIANT.GREEN}>
            <Text tw="my-0 text-2xl font-bold">
              By working together, we will develop tactics and take actions to address crucial
              societal issues like Truth and Reconciliation, climate change, and equity, diversity
              and inclusion.
            </Text>
          </Callout>
          <Text>
            And through these actions we can continue to support our students, employees and
            community from recovery to innovation.
          </Text>
          <Text>
            Thank you for taking the time to review our plan. We look forward to sharing our
            progress with you.
          </Text>
          <Text>Ron J. McKerlie, President & CEO, Mohawk College</Text>
        </div>
        <div tw="md:(col-span-1)">
          <CaptionImage variant={VARIANT.GREEN} caption="Students Graduating at Mohawk College">
            <StaticImage
              style={roundedImg}
              src="../images/students-graduating.jpg"
              alt=""
              layout="fullWidth"
            />
          </CaptionImage>
        </div>
      </div>
    </div>
  </Layout>
);

export default IntroPage;
